<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :md="8" :sm="24">
            <a-form-item
              label="日期"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-range-picker v-model="queryParams.queryDate" />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="服务部门">
              <a-select
                :default-value="0"
                v-model="queryParams.deptTypeId"
                style="width: 180px"
              >
                <a-select-option
                  v-for="d in deptGroup"
                  :key="d.deptTypeId"
                  :value="d.deptTypeId"
                  >{{ d.deptTypeName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="部门" v-if="queryParams.deptTypeId==9">
              <a-select
                :default-value="0"
                v-model="queryParams.deptId"
                style="width: 180px"
                @change="fetchPositionAccList"
              >
                <a-select-option
                  v-for="d in deptList"
                  :key="d.deptId"
                  :value="d.deptId"
                  >{{ d.deptName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="职位" v-if="queryParams.deptTypeId==9">
              <a-select
                :default-value="0"
                v-model="queryParams.deptPositionId"
                style="width: 180px"
              >
                <a-select-option
                  v-for="d in positionList"
                  :key="d.deptPositionId"
                  :value="d.deptPositionId"
                  >{{ d.deptPositionName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <div class="operator">
        <a-button type="primary" ghost @click="exportExcel">导出Excel</a-button>
      </div>
      <!-- 表格区域 -->
      <a-table
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.userId"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 900 }"
      >
      </a-table>
    </div>
  </a-card>
</template>
<script>
import RangeDate from "@/components/datetime/RangeDate";
import { mapState } from "vuex";
import moment from "moment";
import { uniqueByKey } from "@/utils/common.js";

export default {
  name: "sign",
  components: { RangeDate },
  data() {
    return {
      advanced: false,
      deptGroup: [
        { deptTypeId: 0, deptTypeName: "全部" },
        { deptTypeId: 9, deptTypeName: "伊顿电力设备有限公司" },
      ],
      currentUser: [],
      positionAccList:[],
      deptList: [
        { deptId: 0, deptName: "全部" },
      ],
      positionList: [
        { deptPositionId: 0, deptPositionName: "全部" },
      ],
      dataSource: [],
      queryParams: {
        queryDate: [
          moment(new Date(), "YYYY-MM-DD").subtract(30, "day"),
          moment(new Date(), "YYYY-MM-DD").add(1, "day"),
        ],
        deptTypeId: 0,
        deptId: 0,
        deptPositionId: 0,
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.dict.dicts.user_sign_status,
    }),
    columns() {
      return [
        {
          title: "员工编号",
          dataIndex: "empno",
          width: 150,
        },
        {
          title: "用户名",
          dataIndex: "username",
          width: 150,
        },
        {
          title: "部门",
          dataIndex: "deptName",
          width: 200,
        },
        {
          title: "职位",
          dataIndex: "deptPositionName",
          width: 200,
        },
        {
          title: "工单数",
          dataIndex: "taskCount",
          width: 200,
        },
        {
          title: "出差天数",
          dataIndex: "bizTripDays",
          width: 200,
        },
      ];
    },
  },
  mounted() {
    this.fetch();
    this.loadCurrentUser();
  },
  methods: {
    moment,
    search() {
      this.selectData({
        ...this.queryParams,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      params.beginDateFrom = this.queryParams.queryDate[0].format("YYYY-MM-DD");
      params.endDateTo = this.queryParams.queryDate[1].format("YYYY-MM-DD");
      delete params.queryDate;
      this.$get("task-assign", {
        ...params,
      }).then((r) => {
        let data = r.data;
        this.loading = false;
        this.dataSource = data.data;
      });
    },
    fetchPositionAccList(val) {
      this.$get(`dept-position/V1?deptId=`+val).then((p)=>{
          this.positionList = p.data;
        })
    },
    selectData(params = {}) {
      this.loading = true;
      params.beginDateFrom = moment(this.queryParams.queryDate[0]).format(
        "YYYY-MM-DD"
      );
      params.endDateTo = moment(this.queryParams.queryDate[1]).format(
        "YYYY-MM-DD"
      );
      delete params.queryDate;
      this.$get("task-assign", {
        ...params,
      }).then((r) => {
        let data = r.data;
        this.loading = false;
        this.dataSource = data.data;
      });
    },
    reset() {
      // 重置查询参数
      this.queryParams.deptTypeId = 0;
      this.queryParams.deptId = 0;
      this.queryParams.deptPositionId = 0;
      this.fetch();
    },
    loadCurrentUser() {
      this.$get("/screen/datav/current-users").then((r) => {
        this.currentUser = r.data.data || [];
        // 伊顿内部人员
        const inside = this.currentUser.filter((item) => item.deptTypeId == 9);
        const insideUniqueDept = uniqueByKey(inside, "deptId");
        insideUniqueDept.forEach((item, index, arr) => {
          this.deptList.push({ deptId: item.deptId, deptName: item.deptName });
        });
        const insideUniquePosition = uniqueByKey(inside, "deptPositionId");
        insideUniquePosition.forEach((item, index, arr) => {
          this.positionList.push({
            deptPositionId: item.deptPositionId,
            deptPositionName: item.deptPositionName,
          });
        });

        // 外部顾问
        const outside = this.currentUser.filter((item) => item.deptTypeId != 9);
        const outsideUnique = uniqueByKey(outside, "deptTypeId");
        outsideUnique.forEach((item, index, arr) => {
          this.deptGroup.push({
            deptTypeId: item.deptTypeId,
            deptTypeName: item.deptName,
          });
        });
        //路由访问
        
      });
    },
    exportExcel() {
      let params = {};
      params.beginDateFrom = this.queryParams.queryDate[0].format("YYYY-MM-DD");
      params.endDateTo = this.queryParams.queryDate[1].format("YYYY-MM-DD");
      delete params.queryDate;
      this.$export("task-assign/excel", {
        ...params,
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../utils/Common.less";
.test {
	color: #42b984;
	font-size: 1.1rem;
	font-weight: 600;
}
</style>